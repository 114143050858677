import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/HomePage";
import UpcomingPage from "../pages/UpcomingPage";
import MatchPage from "../pages/MatchPage";
import StandingsPage from "../pages/StandingsPage";
import SearchPage from "../pages/SearchPage";
import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";
import FantasyGamePage from "../pages/FantasyGamePage";
import MatchContestsPage from "../pages/MatchContestsPage";
import CreateTeamPage from "../pages/CreateTeamPage";
import CreatedTeamPage from "../pages/CreatedTeamPage";
import UserMatchesPage from "../pages/UserMatchesPage";
import PointsPage from "../pages/PointsPage";
import Auth from "../components/auth/Auth";
import RecentPage from "../pages/RecentPage";
import PageNotFound from "../pages/PageNotFound";
import NotificationsPage from "../pages/NotificationsPage";
import PredictAndWinPage from "../pages/PredictAndWinPage";
import PredictMatchPage from "../pages/PredictMatchPage";
import PredictMatchLeaderboard from "../pages/PredictMatchLeaderboard";
import UserPredictionMatches from "../pages/UserPredictionMatches";
import ErrorBoundary from "../components/ErrorBoundary";
import UserPredictionPointsPage from "../pages/UserPredictionPointsPage";
import LeaderboardPage from "../pages/LeaderboardPage";

const Routing = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/recent/matches",
      element: <RecentPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/upcoming/matches",
      element: <UpcomingPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/match/:id/:homeTeamId/:awayTeamId",
      element: <MatchPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/team/standings",
      element: <StandingsPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/match/search",
      element: <SearchPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/login",
      element: <LoginPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/signup",
      element: <SignupPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/fantasy/game",
      element: <FantasyGamePage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/match/contests/:id/:homeTeamId/:awayTeamId",
      element: (
        <Auth>
          <MatchContestsPage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/match/create-team/:id/:homeTeamId/:awayTeamId/:contestId",
      element: (
        <Auth>
          <CreateTeamPage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/match/created-team/:id/:homeTeamId/:awayTeamId/:contestId",
      element: (
        <Auth>
          <CreatedTeamPage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/user/matches",
      element: <UserMatchesPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/user/points",
      element: <PointsPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/notifications",
      element: <NotificationsPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/fantasy/predict-and-win",
      element: <PredictAndWinPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/match/predict/:id/:homeTeamId/:awayTeamId",
      element: (
        <Auth>
          <PredictMatchPage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/user/match/prediction/:id/:homeTeamId/:awayTeamId",
      element: (
        <Auth>
          <PredictMatchLeaderboard />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/user/prediction/matches",
      element: (
        <Auth>
          <UserPredictionMatches />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/user/prediction/points",
      element: (
        <Auth>
          <UserPredictionPointsPage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/leaderboard",
      element: (
        <Auth>
          <LeaderboardPage />
        </Auth>
      ),
      errorElement: <ErrorBoundary />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ]);
  return <RouterProvider router={router} />;
};

export default Routing;
